@media only screen and (min-width: $screen-md-min) {
  #content {
    padding-bottom: 75px;
  }
}

#footer {
  background-color: $footer-bg-color;
  color: $footer-text-color;
  margin-top: 2px;
  padding: $grid-gutter-width-half 25px;
  @media only screen and (max-width: $screen-sm-max) {
    .download {
      margin-top: $grid-gutter-width-half;
      .text {
        float: none !important;
        br {
          display: none;
        }
      }
      .buttons {
        a {
          margin-bottom: 10px;
          float: left;
          max-width: 200px;
        }
      }
    }
    .menu {
      margin-right: $grid-gutter-width-half;
    }
  }
  @media only screen and (min-width: $screen-md-min) {
    background-color: rgba($footer-bg-color, 0.7);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    .download {
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      .buttons {
        margin-left: 10px;
        a {
          display: block;
          &:last-of-type {
            margin-bottom: 0;
          }
          img {
            max-height: 20px;
            width: auto;
          }
        }
      }
    }
  }
  /*
  h2 {
    margin-bottom: 10px;
    + p {
      @include font-size(17px);
      font-weight: 600;
      margin: 0;
    }
  }
  .statistic {
    text-align: center;
    .icon {
      margin-bottom: 5px;
      i {
        color: $footer-icon-color;
        @include font-size(75px);
      }
    }
    .number {
      @include font-size(32px);
      font-weight: bold;
    }
    .text {
      font-weight: 600;
      @include font-size(16px);
    }
  }
  */
  .menu {
    ul {
      li {
        margin-right: 20px;
        a {
          font-weight: 600;
          color: $footer-menu-text-color;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  /*
  @media screen and (max-width: $screen-md-max) {
    padding-top: 50px;
  }
  */
  @media screen and (max-width: $screen-sm-max) {
    /*
    padding-top: 40px;
    .statistic {
      margin-bottom: 15px;
    }
    */
    .menu {
      ul {
        li {
          float: none;
          margin: 5px 0 0 0;
          a {
            @include font-size(16px);
          }
        }
      }
    }
  }
  /*
  @media screen and (max-width: $screen-xs-max) {
    padding-top: 30px;
  }
  */
}

@include font-face($icon-font-path, 'Asap Condensed', 'asap-condensed-v2-latin-700', 'Asap Condensed Bold', 'AsapCondensed-Bold', 700, normal);
@if $city == 'klagenfurt' {
  @include font-face($icon-font-path, 'Open Sans', 'OpenSans-Regular', 'Open Sans', 'OpenSans', 400, normal);
  @include font-face($icon-font-path, 'Open Sans', 'OpenSans-Semibold', 'Open Sans Semibold', 'OpenSans-Semibold', 600, normal);
  @include font-face($icon-font-path, 'Open Sans', 'OpenSans-Bold', 'Open Sans Bold', 'OpenSans-Bold', 700, normal);
}

@if $city == 'villach' {
  @include font-face($icon-font-path, 'Roboto Condensed', 'roboto-condensed-v16-latin-regular', 'Roboto Condensed', 'RobotoCondensed-Regular', 400, normal);
}

@include font-face($icon-font-path, 'Material Icons', 'MaterialIcons-Regular', 'Material Icons', 'MaterialIcons', 400, normal);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  @include font-size(24px);
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
}

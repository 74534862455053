html, body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: $base-font-size;
}

html.villach, html.villach body {
  font-size: 16px;
  font-family: 'Arial Narrow', Arial, sans-serif;
}

* {
  font-size: 1rem;
}

// Stop IE scrollbar overlay
@-ms-viewport {
  width: auto;
}

.container-fluid {
  width: 100%;
}

.bg-light-blue {
  background-color: $bg-light-blue;
}

.container-fluid,
.container {
  &.no-padding {
    padding-left: 0;
    padding-right: 0;
    > .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

[class^="col-"],
[class*=" col-"] {
  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

.row {
  &.no-margin {
    margin-left: 0;
    margin-right: 0;
  }
}

.row-centered {
  text-align: center;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.center-align {
  display: flex;
  flex-direction: row;
  height: 100%;
  > [class^="col-"],
  > [class*=" col-"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  @include word-wrap();
}

@for $h from 1 through 6 {
  h#{$h} {
    $baseHeaderSize: $header-base-size - ($header-step-size * ($h - 1));
    @include font-size($baseHeaderSize);
    @media (max-width: $screen-md-max) {
      @include font-size($baseHeaderSize - $header-responsive-step-size * 1);
    }
    @media (max-width: $screen-sm-max) {
      @include font-size($baseHeaderSize - $header-responsive-step-size * 2);
    }
    @media (max-width: $screen-xs-max) {
      @include font-size($baseHeaderSize - $header-responsive-step-size * 3);
      > br {
        display: none;
      }
    }
  }
}

h2 {
  font-weight: 600;
}

html.villach {
  @for $h from 2 through 6 {
    h#{$h} {
      font-size: 1.5rem;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Condensed', 'Arial Narrow', Arial, sans-serif;
  }
}

a {
  transition: color $animation-speed-fast linear;
}

html.villach {
  a {
    color: gray;
    &:hover {
      color: $color-black;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.invisible {
  visibility: hidden;
}

.visuallyhidden {
  position: absolute !important;
  left: -10000px;
  top: auto;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
}

body {
  &:hover {
    .visuallyhidden {
      a, input, select, textarea, button, label {
        display: none !important;
      }
    }
  }
}

.progress {
  background-color: $accent-color;
  height: 8px;
  .bar {
    background-color: $primary-color;
    height: 8px;
  }
}

.background {
  background-color: $content-bg-color;
}

section.block {
  padding-top: 100px;
  padding-bottom: 100px;
  @media screen and (max-width: $screen-md-max) {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  @media screen and (max-width: $screen-sm-max) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  @media screen and (max-width: $screen-xs-max) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.menu {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    @at-root nav#{&} {
      @include clearfix;
      > li {
        float: left;
      }
    }
    > li {
      > a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.container {
  max-width: 100%;
}

button.button,
a.button {
  border: 2px solid $button-color;
  border-radius: $border-radius-base;
  padding: 10px;
  text-transform: uppercase;
  color: $button-text-color;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  min-width: 230px;
  max-width: 100%;
  margin-top: $grid-gutter-width;
  margin-right: $grid-gutter-width-half;
  transition: all $animation-speed-fast linear;
  &.pull-right {
    margin-right: 0;
    @include clearfix();
  }
  @at-root .button-group {
    a.button {
      margin-top: $grid-gutter-width-half;
    }

    a.button:first-of-type {
      margin-top: $grid-gutter-width;
    }

    a.button:last-of-type {
      margin-right: 0;
    }
  }
  &.icon {
    position: relative;
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width;
    .icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: $grid-gutter-width-half;
      transform: translateY(-50%);
      margin-top: -3px;
      img {
        width: auto;
        height: auto;
      }
    }
  }
  &.bigger {
    @include font-size(20px);
  }
  &.primary {
    color: $button-primary-text-color;
    background-color: $button-primary-color;
    border-color: $button-primary-color;
  }
  &:hover {
    text-decoration: none;
    border-color: $button-hover-color;
    background-color: $button-hover-color;
    color: $button-hover-text-color;
    &.primary {
      background-color: $button-primary-hover-color;
      border-color: $button-primary-hover-color;
      color: $button-primary-hover-text-color;
    }
  }
  @media screen and (max-width: $screen-xs-max) {
    min-width: 0;
    width: 100%;
  }
}

html.villach {
  button.button,
  a.button {
    &:hover {
      box-shadow: 0 0 4px 1px rgba(0,0,0,0.33);
    }
  }
}

.background a.button {
  border-color: $bg-button-color;
  color: $bg-button-text-color;
  &.primary {
    color: $bg-button-primary-text-color;
    background-color: $bg-button-primary-color;
    border-color: $bg-button-primary-color;
  }
  &:hover {
    border-color: $bg-button-hover-color;
    background-color: $bg-button-hover-color;
    color: $bg-button-hover-text-color;
    &.primary {
      border-color: $bg-button-primary-hover-color;
      color: $bg-button-primary-hover-text-color;
    }
  }
}

.slick-list {
  .slick-loading & {
    background: none;
  }
}

.slick-slider-progress {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  .progress {
    background-color: $secondary-accent-color-light;
    .bar {
      background-color: $slider-progress-color;
    }
  }
}

.form-messages {
  margin-top: $grid-gutter-width-half;
  .error {
    color: $alert-danger-text;
  }
  .success {
    color: $alert-success-text
  }
}

#content {
  margin: 0 25px;

  .full-width {
    margin-left: -25px;
    margin-right: -25px;
    .inner {
      margin-left: 25px;
      margin-right: 25px;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    margin: 0 $grid-gutter-width-half;
    .full-width {
      margin-left: -$grid-gutter-width-half;
      margin-right: -$grid-gutter-width-half;
      .inner {
        margin-left: $grid-gutter-width-half;
        margin-right: $grid-gutter-width-half;
      }
    }
  }
  .select2 {
    max-width: 100%;
    width: 100%;
  }

  #poi-list-container {
    overflow-y: scroll;
  }

  #google-map-report,
  #google-map {
    background-color: lightgrey;
    text-align: center;
    @include font-size(40px);
    display: flex;
    align-items: center;
    .text {
      width: 100%;
    }
  }

  #poi-list-container {
    ul li {
      a {
        display: block;
        .poi-content {
          padding-left: 155px;
          padding-right: 50px;
          min-height: 150px
        }
        .poi-image {
          min-height:100px;
          width:170px;
          position: absolute;
          top: 10px;
          left: 10px
        }
        .state {
          position: absolute;
          top: 10px;
          right: 0;
          text-align:left;
          width:50px;
        }
      }
    }
  }

  #tracker {

    margin-bottom: 20px;
    ul {
      @extend .list-inline;
      li {
        font-size: 1.3rem;
        padding: 0 15px;
        border-right: 2px solid $color-white;
        img {
          margin-top: -25px;
        }
        span {
          margin-left: 15px;
          margin-right: 5px;
          font-size: 2rem;
          font-weight: bold;
        }
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
          border-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    #poi-list-container {
      margin-top: $grid-gutter-width;
      margin-bottom: $grid-gutter-width-half;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    #tracker {
      ul {
        li {
          display: block;
          border: none;
          padding: 0;
          margin-bottom: 15px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    .embed-responsive {
      margin-top: $grid-gutter-width;
    }
    #poi-list-container {
      ul li {
        a {
          .poi-content {
            padding-left: 0;
            padding-right: 0;
            min-height: 0;
            margin-top: $grid-gutter-width-half;
          }
          .poi-image {
            width: 100%;
            text-align: center;
            min-height:0;
            position: static;
          }
          .state {
            position: absolute;
            top: 10px;
            right: 0;
            text-align:left;
            width:50px;
          }
        }
      }
    }
  }

  #poi-list-container,
  #google-map-report,
  #google-map {
    min-height: 400px!important;
    height: 100vh!important;
    @media screen and (max-width: $screen-md-max) {
      height: 75vh!important;
    }
    @media screen and (max-width: $screen-sm-max) {
      min-height: 200px!important;
      height: 50vh!important;
    }
  }
  #google-map-report {
    height: 500px!important;
    @media screen and (max-width: $screen-md-max) {
      margin-bottom: $grid-gutter-width;
    }
  }
  h2 {
    margin-bottom: 30px;
    + p {
      @include font-size(17px);
      margin: 0;
    }
  }
  .slick-container {
    display: none;
    .boxes {
      margin-top: 100px;
      @media screen and (max-width: $screen-md-max) {
        margin-top: 85px;
      }
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 70px;
      }
      @media screen and (max-width: $screen-xs-max) {
        margin-top: 50px;
      }
    }
    max-width: 100%;
    .slick-prev, .slick-next {
      height: 60px;
      width: 60px;
      background-color: rgba($accent-color, 0.4);
      z-index: 10;
      &:hover {
        background-color: rgba($accent-color, 0.8);
      }
      &:active {
        background-color: rgba($accent-color, 0.9);
      }
      &:before {
        @extend .material-icons;
        @include font-size(60px);
      }
    }
    .slick-disabled {
      display: none;
    }
    .slick-prev {
      left: 0;
      &:before {
        content: "\E5CB";
      }
    }
    .slick-next {
      right: 0;
      &:before {
        content: "\E5CC";
      }
    }
    .slick-dots {
      bottom: -50px;
      z-index: 100;
      li {
        width: 12px;
        height: 12px;
        button {
          background-color: $accent-color;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          &:before {
            content: '';
          }
        }
        &.slick-active {
          button {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

html.villach {
  #content {
    h2 {
      + p {
        font-size: 1rem;
      }
    }
  }
}

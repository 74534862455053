$animation-speed-slow: 0.45s;
$animation-speed-normal: 0.3s;
$animation-speed-fast: 0.15s;

$animation-offset-normal: 4px;
$animation-offset-small: 2px;

$border-radius-xsmall: 1px;
$border-radius-small: 2px;
$border-radius: 5px;
$border-radius-large: 10px;
$border-radius-xlarge: 15px;

$header-bottom-height: 80px;
$header-base-size: 38px;
$header-step-size: 2px;
$header-responsive-step-size: 3px;

$color-black: #000000;
$color-white: #FFFFFF;

$base-font-size: 14px;

$bg-light-blue: #EFF5F9;

/* Villach */
$city: 'villach';
$menu-bg-color: #AE9615;

$primary-color: #FFEA33;
$accent-color: $color-black;

$secondary-accent-color: #C0D8E3;
$secondary-accent-color-light: lighten($secondary-accent-color, 13%);

$header-text-color: $color-white;
$header-menu-text-color: $color-black;

$social-icons-color: $accent-color;

$content-text-color: $color-black;
$content-bg-color: $secondary-accent-color;

$slider-progress-color: $primary-color;

$button-primary-color: $primary-color;
$button-primary-text-color: $color-black;
$button-primary-hover-color: #F9D927;
$button-primary-hover-text-color: $color-black;

$button-color: $color-black;
$button-text-color: $color-black;
$button-hover-color: #F9D927;
$button-hover-text-color: $color-black;

$bg-button-primary-color: $button-primary-color;
$bg-button-primary-text-color: $button-primary-text-color;
$bg-button-primary-hover-color: $button-primary-hover-color;
$bg-button-primary-hover-text-color: $button-primary-hover-text-color;

$bg-button-color: $button-color;
$bg-button-text-color: $button-text-color;
$bg-button-hover-color: $button-hover-color;
$bg-button-hover-text-color: $button-hover-text-color;

$footer-text-color: $color-black;
$footer-icon-color: $accent-color;
$footer-menu-text-color: $color-black;
$footer-bg-color: $primary-color;

/* Klagenfurt */


$city: 'klagenfurt';
$primary-color: #7ABBC3;
$accent-color: #575757;

$secondary-accent-color: $primary-color;
$secondary-accent-color-light: #ECF5F9;

$header-text-color: $color-white;
$header-menu-text-color: $color-black;

$social-icons-color: $primary-color;

$content-text-color: $color-black;
$content-bg-color: $secondary-accent-color;

$slider-progress-color: $accent-color;

$button-primary-color: $primary-color;
$button-primary-text-color: $color-black;
$button-primary-hover-color: $accent-color;
$button-primary-hover-text-color: $primary-color;

$button-color: $color-black;
$button-text-color: $color-black;
$button-hover-color: $accent-color;
$button-hover-text-color: $primary-color;

$bg-button-primary-color: $color-white;
$bg-button-primary-text-color: $button-primary-text-color;
$bg-button-primary-hover-color: $button-primary-hover-color;
$bg-button-primary-hover-text-color: $button-primary-hover-text-color;

$bg-button-color: $color-white;
$bg-button-text-color: $color-white;
$bg-button-hover-color: $button-hover-color;
$bg-button-hover-text-color: $button-hover-text-color;

$footer-text-color: $color-white;
$footer-icon-color: $primary-color;
$footer-menu-text-color: $color-white;
$footer-bg-color: $primary-color;


.box {
  a {
    color: $color-black;
  }
  &:not(.spacer) {
    box-shadow: 5px 0 6px -1px rgba($color-black, 0.2),
    -5px 0 6px -1px rgba($color-black, 0.2);
  }
  .header {
    padding: $grid-gutter-width-half;
  }
  .content {
    padding: $grid-gutter-width-half;
  }
  .footer {
    padding: $grid-gutter-width-half;
    background-color: $secondary-accent-color-light;
  }
}

.boxes {
  margin-top: 50px;
}

.box.style1 {
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  float: left;
  @media (min-width: $screen-sm-min) {
    width: 270px;
    margin-right: $grid-gutter-width;
    margin-left: 0;
  }
  &.spacer {
    margin: 0;
    &.start {
      display: none;
    }
    @media (min-width: $screen-sm-min) {
      &.start {
        display: block;
        width: calc((100vw - (#{$container-sm} - #{$grid-gutter-width-half})) / 2);
      }
    }
    @media (min-width: $screen-md-min) {
      &.start {
        width: calc((100vw - (#{$container-md} - #{$grid-gutter-width-half})) / 2);
      }
    }
    @media (min-width: $screen-lg-min) {
      &.start {
        width: calc((100vw - (#{$container-lg} - #{$grid-gutter-width-half})) / 2);
      }
    }
  }
  .progress {
    background-color: $accent-color;
  }
  .header {
    .time {
      position: relative;
      display: inline-block;
      padding-left: 40px;
      font-weight: bold;
      @include font-size(12px);
      margin-top: 5px;
      i {
        color: $primary-color;
        @include font-size(30px);
        position: absolute;
        top: -6px;
        left: 0;
      }
    }
  }
  .content {
    a.button {
      width: 100%;
      min-width: 0;
      margin: 20px 0 10px 0;
    }
    .title {
      @include font-size(17px);
      font-weight: 600;
      text-transform: uppercase;
      line-height: 20px;
      margin-bottom: $grid-gutter-width-half;
    }
    .text {
      font-weight: 600;
    }
  }
  .footer {
    padding: $grid-gutter-width-half 0;
    .statistics {
      padding: 0 $grid-gutter-width-half;
      .statistic {
        a {
          display: block;
        }
        width: 33%;
        float: left;
        padding: 0 7px;
        border-left: 1px solid $accent-color;
        &:first-of-type {
          border-left: none;
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
        .number {
          text-align: left;
          display: inline-block;
          position: relative;
          padding-left: 20px;
          @include font-size(18px);
          font-weight: bold;
          i {
            position: absolute;
            top: 2px;
            left: 0;
            @include font-size(20px);
            color: $secondary-accent-color;
          }
        }
        .text {
          text-align: left;
          @include font-size(10px);
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .slick-container {
    @include container-fixed($grid-gutter-width);
  }
  .slick-prev {
    left: -15px !important;
  }
  .slick-next {
    right: -15px !important;
  }
  .box.style1 {
    width: calc(100vw - (#{$grid-gutter-width * 2}));
  }
}

.box.style2 {
  background-color: $color-white;
  margin-bottom: $grid-gutter-width-half;
  margin-top: $grid-gutter-width-half;
  .header {
    .left {
      float: left;
    }
    .right {
      margin-left: 5px;
      float: left;
      .name {
        font-weight: bold;
        line-height: 10px;
      }
      .date {
        @include font-size(11px);
      }
    }
  }
  .content {
    .title {
      @include font-size(17px);
      font-weight: 600;
      text-transform: uppercase;
      line-height: 20px;
      margin-bottom: $grid-gutter-width;
    }
    .tags {
      margin-bottom: $grid-gutter-width-half - 3px;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        @include clearfix();
        li {
          float: left;
          margin-right: 5px;
          margin-bottom: 3px;
          a {
            @include font-size(10px);
            background-color: $secondary-accent-color-light;
            color: $color-black;
            padding: 2px 5px;
          }
        }
      }
    }
  }
  .footer {
    text-align: center;
    .days {
      @include font-size(11px);
      margin-bottom: $grid-gutter-width-half;
    }
    .progress {
      border-radius: $border-radius-large;
      background-color: $accent-color;
      margin: 0 $grid-gutter-width-half $grid-gutter-width-half $grid-gutter-width-half;
      .bar {
        border-radius: $border-radius-large;
      }
    }
    .count {
      margin: $grid-gutter-width-half 0;
      .number {
        display: inline-block;
        position: relative;
        @include font-size(38px);
        font-weight: bold;
      }
      i {
        position: absolute;
        top: 15px;
        left: -25px + (-$grid-gutter-width-half);
        @include font-size(30px);
      }
    }
    .information {
      font-weight: bold;
      @include font-size(12px);
    }
  }
}

@mixin font-face($font-path, $font-name, $file-name, $local, $local2, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    font-style: $style;
    font-weight: $weight;
    src: url($font-path + $file-name + '.eot');
    src: local(quote($local)), local(quote($local2)),
    url($font-path + $file-name + '.eot?#iefix') format('embedded-opentype'),
    url($font-path + $file-name + '.woff2') format('woff2'),
    url($font-path + $file-name + '.woff') format('woff'),
    url($font-path + $file-name + '.ttf') format('truetype'),
    url($font-path + $file-name + '.svg#' + str-replace($font-name, ' ', '')) format('svg');
  }
}

@mixin fixed-size($size) {
  @if unitless($size) {
    $size: $size * 1px;
  }
  width: $size;
  min-width: $size;
  max-width: $size;
  height: $size;
  min-height: $size;
  max-height: $size;
}

@mixin font-size($size) {
  @if unitless($size) {
    $size: $size * 1px;
  }
  font-size: $size;
  font-size: ($size / $base-font-size) * 1rem;
}

@mixin line-height($height-value: 12) {
  line-height: $height-value * 1px;
  line-height: (1 / ($base-font-size / ($base-font-size * 0 + 1)) * $height-value * 1rem);
}

@mixin position($type, $top: null, $right: null, $bottom: null, $left: null) {
  position: $type;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin css-triangle($color, $direction, $size: 6px, $round: false) {
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin overflow-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

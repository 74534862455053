$bootstrap-fileinput-img-path: '../../../images/bs_upload/';

/*
  Bootstrap Variables
*/
$icon-font-path: '../../../fonts/';

$border-radius-base: $border-radius;
$border-radius-large: $border-radius-large;
$border-radius-small: $border-radius-small;

$grid-gutter-width: 30px;
$grid-gutter-width-half: ($grid-gutter-width / 2);

/*
  Font Awesome Variables
*/
$fa-font-path: '../../../fonts/';

$navbar-height: $header-bottom-height;
$navbar-margin-bottom: 0;
$navbar-border-radius: 0;
$navbar-default-color: $color-black;
$navbar-default-bg: transparent;
$navbar-default-border: transparent;
$navbar-default-link-color: $color-black;
$navbar-default-link-hover-color: $primary-color;
$navbar-default-link-hover-bg: $accent-color;
$navbar-default-link-active-color: $primary-color;
$navbar-default-link-active-bg: $accent-color;
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-icon-bar-bg: $color-black;
$navbar-default-toggle-border-color: transparent;

@if $city == 'villach' {
  $navbar-default-color: $color-black;
  $navbar-default-bg: transparent;
  $navbar-default-border: transparent;
  $navbar-default-link-color: $color-black;
  $navbar-default-link-hover-color: $color-black;
  $navbar-default-link-hover-bg: $color-white;
  $navbar-default-link-active-color: $color-black;
  $navbar-default-link-active-bg: $color-white;
  $navbar-default-toggle-hover-bg: transparent;
  $navbar-default-toggle-icon-bar-bg: $color-black;
  $navbar-default-toggle-border-color: transparent;
}

$dropdown-bg: $primary-color;
$dropdown-border: transparent;
$dropdown-divider-bg: $accent-color;
$dropdown-link-color: $color-black;
$dropdown-link-hover-color: $primary-color;
$dropdown-link-hover-bg: $accent-color;
$dropdown-link-active-color: $primary-color;
$dropdown-link-active-bg: $accent-color;

@if $city == 'villach' {
  $dropdown-bg: $color-white;
  $dropdown-border: transparent;
  $dropdown-divider-bg: $accent-color;
  $dropdown-link-color: $color-black;
  $dropdown-link-hover-color: $color-black;
  $dropdown-link-hover-bg: $color-white;
  $dropdown-link-active-color: $color-black;
  $dropdown-link-active-bg: $color-white;
}

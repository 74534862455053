#header {
  color: $header-text-color;
  .top {
    position: relative;
    background: url('../images/header_image.jpg') no-repeat 50% 50%;
    background-size: cover;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.2);
    }
    .logo {
      margin-left: -15px;
    }
    .header-title {
      padding-top: $grid-gutter-width;
      padding-bottom: 150px;
      position: relative;
      font-family: 'Asap Condensed', 'Verdana', sans-serif !important;
      .title {
        background-color: $primary-color;
        padding: 0 10px;
        color: $color-black;
        @include font-size(48px);
        text-transform: uppercase;
        display: inline-block;
        line-height: 1;
      }
      .image-title {
        position: absolute;
        top: $grid-gutter-width;
        left: -130px;
      }
      .sub-title {
        margin-top: $grid-gutter-width;
        @include font-size(16px);
        letter-spacing: 1px;
        font-weight: 600;
      }
    }
    .menu {
      margin-right: -15px;
      float: right;
      padding: $grid-gutter-width;
      font-weight: 600;
      ul {
        li {
          position: relative;
          margin-right: $grid-gutter-width;
          &:last-child {
            margin-right: 0;
          }
          a {
            display: block;
            color: $color-white;
            text-transform: uppercase;
            padding-left: 2.143rem;
            &:hover {
              text-decoration: underline;
              i {
                text-decoration: none;
              }
            }
            i {
              position: absolute;
              top: 3px;
              left: 0;
              line-height: 1.071rem;
              @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                left: -30px;
              }
            }
          }
        }
      }
    }
    @media (max-width: $screen-xs-max) {
      .image-title {
        position: static;
      }
      .menu {
        float: none;
      }
      .logo {
        margin: 0;
        text-align: center;
      }
      .menu {
        text-align: center;
        padding: 0;
        ul {
          li {
            margin: 5px 0 0 0;
            float: none;
            a {
              i {
                position: static;
                display: block;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      .header-title {
        padding: ($grid-gutter-width * 1.5) 0;
      }
    }
    @media (max-width: 1450px) {
      .image-title {
        position: static !important;
        margin-bottom: $grid-gutter-width;
      }
    }
    @media (max-width: $screen-md-max) {
      .menu {
        margin-top: $grid-gutter-width-half;
        margin-right: 0;
        ul {
          li {
            float: none;
            margin-top: $grid-gutter-width-half;
            a {
              padding: 0;
            }
          }
        }
      }
      .header-title {
        text-align: center;
        padding-bottom: 100px;
      }
    }
    @media (max-width: $screen-sm-max) {
      .header-title {
        padding-bottom: 30px;
      }
    }
  }
  .bottom {
    position: relative;
    height: $header-bottom-height;
    line-height: $header-bottom-height;
    background-color: $primary-color;
    .navbar {
      border: none;
      .dropdown-menu .divider {
        background-color: $accent-color;
      }
      .navbar-header {
        height: $header-bottom-height;
        margin-left: -$grid-gutter-width;
        margin-right: -$grid-gutter-width;
      }
      @media screen and (min-width: $screen-sm-min) {
        a.dropdown-toggle {
          width: 182px !important;
        }
        .navbar-nav {
          > li {
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0;
            }
            > a {
              font-weight: 600;
              @include font-size(16px);
              @if $city == 'klagenfurt' {
                font-style: italic;
              }
              @if $city == 'villach' {
                text-transform: uppercase;
              }
              min-width: $header-bottom-height;
              text-align: center;
              > i {
                display: inline-block;
                height: 0;
                @include font-size(28px);
                margin-top: -2rem;
                vertical-align: middle;
              }
            }
            @if $city == 'villach' {
              &.dropdown {
                &::after {
                  position: absolute;
                  content: "";
                  display: block;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  @include css-triangle($color-black, down, 0.65rem);
                }
                &.open {
                  &::after {
                    content: none;
                  }
                }
              }
            }
            .dropdown-menu {
              border: none;
              min-width: $header-bottom-height;
              > li {
                > a {
                  @include font-size(16px);
                  padding-top: 5px;
                  padding-bottom: 5px;
                }
                @if $city == 'villach' {
                  &.active > a,
                  > a:hover {
                    font-weight: 600;
                  }
                  > a::after {
                    display: block;
                    content: attr(title);
                    font-weight: bold;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: $screen-xs-max) {
        .navbar-collapse {
          background-color: $primary-color;
          margin: 0 (-$grid-gutter-width) $grid-gutter-width (-$grid-gutter-width);
          padding-top: 1px;
          .navbar-nav {
            margin-top: 0;
            > li {
              > a {
                font-style: italic;
                font-weight: 600;
              }
            }
          }
          a {
            @include font-size(18px);
            padding-top: 10px;
            padding-bottom: 10px;
            > i {
              display: inline-block;
              height: 0;
              @include font-size(28px);
              margin-top: -2rem;
              vertical-align: middle;
            }
          }
        }
        .navbar-toggle {
          float: none;
          border-radius: 0;
          height: $header-bottom-height;
          width: $header-bottom-height;
          margin: 0;
          text-align: center;
          .icon-bar {
            margin-left: auto;
            margin-right: auto;
            width: 30px;
            height: 3px;
            border-radius: 0;
          }
          @if $city == 'villach' {
            &:hover, &:not(.collapsed) {
              background-color: $color-white;
              .icon-bar {
                background-color: $color-black;
              }
            }
          }
          @if $city == 'klagenfurt' {
            &:hover, &:not(.collapsed) {
              background-color: $accent-color;
              .icon-bar {
                background-color: $primary-color;
              }
            }
          }
        }
      }
    }
    .menu {
      ul {
        li {
          a {
            color: $header-menu-text-color;
          }
        }
      }
    }
    .font-size-selection {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      @include clearfix();
      li {
        margin-right: 5px;
        float: left;
        a {
          padding: 0;
          font-weight: 600;
          min-width: 80px;
          text-align: center;
          color: $navbar-default-link-color;
        }
        &.active a,
        a:hover,
        a:focus,
        a:active {
          color: $navbar-default-link-hover-color !important;
          background-color: $navbar-default-link-hover-bg !important;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }

      li:nth-of-type(1) a {
        font-size: 12px;
      }
      li:nth-of-type(2) a {
        font-size: 18px;
      }
      li:nth-of-type(3) a {
        font-size: 24px;
      }
      li a {
        font-style: normal;
      }
      @media screen and (max-width: 1500px) {
        // right: $header-bottom-height + 5px;
      }
      @media screen and (max-width: 435px) {
        display: none;
      }
    }
    .social-share {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: pointer;
      .header {
        height: $header-bottom-height;
        width: $header-bottom-height;
        text-align: center;
        text-transform: uppercase;
        background-color: $accent-color;
        color: $primary-color;
        font-weight: 600;
      }
      .menu {
        display: none;
        position: relative;
        .arrow-down {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $accent-color;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -10px;
        }
        ul {
          li {
            a {
              background-color: #F0F0F0;
              display: block;
              height: $header-bottom-height;
              width: $header-bottom-height;
              text-align: center;
              @include font-size(28px);
              i {
                color: $social-icons-color;
              }
              &:hover {
                background-color: $primary-color;
                i {
                  color: $accent-color;
                }
              }
            }
          }
        }
      }
      &.open {
        .menu {
          display: block;
        }
      }

      @media screen and (max-width: 1500px) {
        left: auto;
        right: 0;
        &.open {
          .menu {
            display: none;
          }
        }
      }
    }
    @media screen and (min-width: $screen-sm-min) {
      .navbar {
        >.container-fluid {
          padding-left: 0;
          > .navbar-collapse {
            padding-left: 0;
          }
        }
      }
    }
  }
}

/*
html.villach {
  #header {
    .bottom {
      @media screen and (min-width: $screen-sm-min) {
        .navbar {
          .navbar-nav {
            > li {
              &.open > a,
              &.active > a,
              > a:focus,
              > a:active,
              > a:hover {
                background: transparent;
                color: $navbar-default-link-color;
                &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  bottom: 15px;
                  left: 10px;
                  width: calc(100% - 20px);
                  height: 3px;
                  background: $menu-bg-color;
                }
                &.dropdown-toggle {
                  &::after {
                    left: 5px;
                  }
                }
              }
            }
          }
          @media screen and (min-width: $screen-sm-min) {
            .navbar-nav {
              > li {
                .dropdown-menu {
                  border: none;
                }
              }
            }
          }
        }
      }
      .font-size-selection {
        li {
          &.active a,
          a:hover,
          a:focus,
          a:active {
            background-color: transparent !important;
            color: $navbar-default-link-color !important;
            &::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 15px;
              left: 10px;
              width: calc(100% - 20px);
              height: 3px;
              background: $menu-bg-color;
            }
          }
        }
      }
    }
  }
}
*/

#report-modal {
  padding: 0;
  .sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .comments-container .sk-fading-circle {
    margin: 50px auto;
  }

  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }

  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
  .report-content {
    display: block;
  }
  .modal-body > .sk-fading-circle {
    display: none;
  }
  &.loading {
    .report-content {
      display: none;
    }
    .sk-fading-circle {
      display: block;
    }
  }

  .modal-dialog {
    .modal-content {
      border-radius: 0;
      .modal-body {
        padding: 0;
        .close {
          position: absolute;
          color: #fff;
          text-shadow: -1px 0 $color-black, 0 1px $color-black, 1px 0 $color-black, 0 -1px $color-black;
          opacity: 1;
          float: none;
          height: 32px;
          width: 32px;
          line-height: 32px;
          text-align: center;
          top: 0;
          right: 0;
          z-index: 999999;
          span {
            font-size: 32px;
          }
        }
        .slick-slider {
          img {
            min-height: 325px;
            max-height: 325px;
            width: 100%;
            object-fit: cover;
          }
          margin-bottom: 0;
          .slick-dots {
            z-index: 100;
            bottom: 15px;
            li {
              width: 12px;
              height: 12px;
              button {
                background-color: $accent-color;
                border-radius: 50%;
                width: 12px;
                height: 12px;
                &:before {
                  content: '';
                }
              }
              &.slick-active {
                button {
                  background-color: $primary-color;
                }
              }
            }
          }
        }
        .inner-content {
          padding: $grid-gutter-width-half;
          .information {
            @include clearfix();
            .clearfix > div,
            > div {
              @include clearfix();
              margin-bottom: 5px;
            }
            i {
              @include font-size(20px);
              float: left;
              margin-right: 5px;
            }
            span {
              float: left;
            }
            .icon-offset {
              margin-left: 25px;
              margin-top: -5px;
            }
            .report-category {
              i {
                float: left;
              }
              @include clearfix();
              ul {
                float: left;
                list-style: none;
                padding: 0;
                margin: 0;
                > li {
                  float: left;
                }
              }
            }
          }
          .report-info-message {
            background-color: $primary-color;
            color: $accent-color;
            padding: 10px;
            margin: 10px 0;
            text-align: center;
            a {
              font-weight: 600;
            }
          }
          > .content {
            margin: 15px 0;

            .report-switch-button {
              margin: 15px auto 5px auto;
              cursor: pointer;
              position: relative;
              border-radius: 50%;
              background-color: transparent;
              border: 4px solid $primary-color;
              transition: all 0.1s linear;
              @include size(80px);
              i:not(.add):not(.remove) {
                @include size(72px);
                text-align: center;
                line-height: 72px;
                @include font-size(40px);
                color: $accent-color;
              }
              .remove {
                display: none;
              }
              &.active {
                background-color: $primary-color;
                .add {
                  display: none;
                }
                .remove {
                  display: block;
                }
              }
              .add, .remove {
                position: absolute;
                bottom: 10px;
                right: 10px;
                color: $primary-color;
                background-color: $accent-color;
                border-radius: 50%;
                @include font-size(16px);
              }
              &:hover {
                background-color: $primary-color;
                .add, .remove {
                  color: $color-white;
                }
              }
            }
            .report-switch-button-text {
              text-align: center;
              @include font-size(16px);
              font-weight: 600;
            }
          }
          .comments {
            list-style: none;
            margin: 0;
            padding: 0;
            > li {
              border-bottom: 4px solid #ebebeb;
              padding: 10px;
              .image {
                float: left;
                width: 60px;
              }
              .content {
                float: left;
                width: 100%;
                margin-left: -60px;
                padding-left: 75px;
                .name {
                  font-weight: 600;
                  @include font-size(16px);
                }
                .text {
                  margin: 5px 0;
                }
                .date {
                  color: #7d7d7d;
                }
              }
              &:nth-of-type(odd) {
                background-color: #d7d7d7;
              }
              &:last-of-type {
                border-bottom: none;
              }
            }
          }
          form {
            @include clearfix();
            margin-top: 15px;
            label {
              font-weight: 600;
            }
            textarea {
              border-radius: 0;
              box-shadow: none;
            }
            button {
              border-radius: 0;
              float: right;
              margin-top: 15px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.featherlight {
  .featherlight-content {
    padding: 10px 10px 0;
    border-bottom: 10px solid transparent;
    .featherlight-close {
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 100;
      color: #fff;
      text-shadow: -1px 0 $color-black, 0 1px $color-black, 1px 0 $color-black, 0 -1px $color-black;
      opacity: 1;
      @include font-size(30px);
      float: none;
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
    }
    .featherlight-next,
    .featherlight-previous {
      top: 10px;
      span {
        text-shadow: -1px 0 $color-black, 0 1px $color-black, 1px 0 $color-black, 0 -1px $color-black;
      }
    }
    .featherlight-previous {
      left: 10px;
    }
    .featherlight-next {
      right: 10px;
    }
  }
}
